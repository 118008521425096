import React, {useEffect, useMemo, useState} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { Cluster, clusterApiUrl } from '@solana/web3.js';
import { ToastContainer } from 'react-toastify';
import AppLayout from './components/AppLayout';
import Stake from './pages/Stake';
import Gauge from './pages/Gauge';
import Admin from './pages/Admin';
import { DataRequestBuilder, RadixDappToolkit} from '@radixdlt/radix-dapp-toolkit';
import { RadixProvider } from './components/Navbar/radix/RadixProvider';
import {config} from "./components/Navbar/config";
import { GatewayProvider } from './components/Navbar/radix/GatewayProvider';
import { GatewayApiClient } from '@radixdlt/babylon-gateway-api-sdk';
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#37b06f',
    }
  }
});

const network = process.env.REACT_APP_NETWORK || 'devnet';
export const rdt = RadixDappToolkit({
  networkId: 1,
  dAppDefinitionAddress:
  config.dAppDefinitionAddress,
})



function App() {
  const endpoint = useMemo(() => clusterApiUrl(network as Cluster), [network]);
  const [state, setState] = useState<RadixDappToolkit | undefined>()
  const [gateway, setGateway] = useState<GatewayApiClient | undefined>()
  // Initialize Radix Dapp Toolkit in the client

  const wallets = useMemo(
    () => [new PhantomWalletAdapter()],
    [network]
  );
  useEffect(() => {
    const radixDappToolkit = RadixDappToolkit({
      networkId: config.network.networkId,
      dAppDefinitionAddress: config.dAppDefinitionAddress,
      // logger: createLogger(2),
    })

    const gatewayApi = GatewayApiClient.initialize({
      networkId: config.network.networkId,
      applicationName: 'JewelSwap Liquid Staking',
      applicationDappDefinitionAddress: config.dAppDefinitionAddress,
    })

    setGateway(gatewayApi)

    radixDappToolkit.walletApi.setRequestData(
        DataRequestBuilder.accounts().atLeast(1)
    )
  console.log("sett");
    console.log(radixDappToolkit);
    setState(radixDappToolkit)

    return () => {
      radixDappToolkit.destroy()
    }
  }, [])
  if (!state) return null
  if (!gateway) return null
  return (
    <div>
      <GatewayProvider value={gateway}>
      <RadixProvider value={state}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route path='*' element={<Navigate to='/stake' />} />
                  <Route path='/' element={<AppLayout />} >
                    <Route path='/' element={<Navigate to='/stake' />} />
                    <Route path='/stake' element={<Stake />} />
                    <Route path='/gauge' element={<Gauge />} />
                    <Route path='/admin' element={<Admin />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </WalletModalProvider>
          <ToastContainer autoClose={3000} />
        </WalletProvider>
      </ConnectionProvider>
      </RadixProvider>
      </GatewayProvider>
    </div>
  );
}

export default App;
