import { useCallback, useEffect, useState } from 'react'
import { useDappToolkit } from './useDappToolkit'
import {Account, DataRequestBuilder} from '@radixdlt/radix-dapp-toolkit'
import { switchMap, map } from 'rxjs'
import {
    FungibleResource,
    NonFungibleResource,
    transformFungibleTokens,
    transformNonFungibleTokens,
} from '../transformers/addTokens'
import { useGateway } from './useGateway'
import { FungibleResourcesVaultCollection, NonFungibleResourcesVaultCollection, State } from '@radixdlt/babylon-gateway-api-sdk'

export type AccountWithTokens = Account &
    Account & { fungibleTokens: Record<string, FungibleResource> } & {
    nonFungibleTokens: Record<string, NonFungibleResource[]>
}

const useWithTokens = (stateApi: State) => {
    return useCallback(
      (accounts: Account[]) =>
        stateApi
          .getEntityDetailsVaultAggregated(
            accounts.map((account) => account.address)
          )
          .then((data: any[]) =>
            Promise.all(
              data.map((item) =>
                transformFungibleTokens(item?.fungible_resources)
                  .then((fungibleTokens) => ({
                    ...accounts.find(
                      (account) => account.address === item.address
                    )!,
                    fungibleTokens,
                  }))
                  .then((values) =>
                    transformNonFungibleTokens(stateApi, item?.non_fungible_resources).then(
                      (nonFungibleTokens) => ({
                        ...values,
                        nonFungibleTokens,
                      })
                    )
                  )
              )
            )
          ),
      [stateApi]
    )
  }

export const useAccounts = () => {
    const dAppToolkit = useDappToolkit()
    const gatewayApi = useGateway()
    const [state, setState] = useState<{
        accounts: AccountWithTokens[]
        status: 'pending' | 'success' | 'error'
        hasLoaded: boolean
    }>({ accounts: [], status: 'pending', hasLoaded: false })

    const withTokens = useWithTokens(gatewayApi.state)

    useEffect(() => {
        const subscription = dAppToolkit.walletApi.walletData$
            .pipe(
                map((walletData) => walletData.accounts),
                switchMap((accounts) => {
                    setState((prev) => ({ ...prev, status: 'pending' }))
                    return withTokens(accounts)
                        .then((accounts: any[]) => {
                            console.log("accounts");
                            console.log(accounts);
                            setState({
                                accounts,
                                status: 'success',
                                hasLoaded: true,
                            })
                        })
                        .catch(() => {
                            setState({ accounts: [], status: 'error', hasLoaded: true })
                        })
                })
            )
            .subscribe()

        return () => {
            subscription.unsubscribe()
        }
    }, [dAppToolkit, withTokens, setState])

    return {
        state,
        refresh: useCallback(() => {
            setState((prev) => ({ ...prev, status: 'pending' }))
            return withTokens(state.accounts)
                .then((accounts: any) => {
                    console.log("accounts");
                    console.log(accounts);
                    setState({ accounts, status: 'success', hasLoaded: true })
                })
                .catch(() => {
                    setState({ accounts: [], status: 'error', hasLoaded: true })
                })
        }, [state.accounts, withTokens]),
    }
}
export interface PrintDateTimeType {
    years?: string,
    days: string,
    hours: string,
    minutes: string,
    seconds: string,
}
export const convertSecondsToPrintDateTime = (totalSeconds: number): PrintDateTimeType => {
    const days = Math.floor(totalSeconds / (3600 * 24));
    totalSeconds %= 3600 * 24;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
        days: days.toFixed(),
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
    };
};

export const convertSecondsToPrintDateTimeString = (totalSeconds: number): string => {
    const { days, hours, minutes, seconds } = convertSecondsToPrintDateTime(totalSeconds);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

