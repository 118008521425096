import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import Swiper from 'react-id-swiper';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    InputAdornment,
    InputBase,
    LinearProgress,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';
import { useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import * as anchor from '@coral-xyz/anchor';
import { ASSOCIATED_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import {
    convertSecondsToDateTime,
    getApy,
    getBalance,
    getTokenBalance,
    getTokenSupply,
    globalStatePubkey,
    reserveVault,
    useContractInteractor
} from '../../utils';
import { IEpochInfo, IGlobalState, IUserInfoAccount } from '../../interfaces';
import { CONTRACT_DEPLOYED_AT, MAX_FEE_TO_DEPOSIT, MINUTE_IN_MS, MIN_DEPOSIT_AMOUNT, UNBOINDING_PERIOD } from '../../config';
import { wlValidators } from '../../data';
import solLogo from '../../assets/images/sol_logo.svg';
import jwlsolLogo from '../../assets/images/jwlsol_logo.svg';
import {useSendTransactionManifest} from "../../components/Navbar/hooks/useSendTransactionManifest";
import {convertSecondsToPrintDateTimeString, useAccounts} from "../../components/Navbar/hooks/useAccounts";
import {config} from "../../components/Navbar/config";
import {useConnectButtonState} from "../../components/Navbar/hooks/useConnectButtonState";
import {useSpring} from "@react-spring/web";
import BigNumber from "bignumber.js";
import {useWellKnownAddresses} from "../../components/Navbar/hooks/useWellKnownAddresses";
import {usePersona} from "../../components/Navbar/hooks/usePersona";
import {rdt} from "../../App";
import {DataRequestBuilder, TransactionStatus} from "@radixdlt/radix-dapp-toolkit";
import {useDappToolkit} from "../../components/Navbar/hooks/useDappToolkit";
import {useFetch} from "../../components/Navbar/hooks/useFetch";
import {NonFungibleResource, XrdClaimNonFungibleResource} from "../../components/Navbar/transformers/addTokens";
import {
    FungibleResourcesCollectionItemVaultAggregatedVaultItem
} from "@radixdlt/babylon-gateway-api-sdk/dist/generated/models/FungibleResourcesCollectionItemVaultAggregatedVaultItem";
import { useGateway } from '../../components/Navbar/hooks/useGateway';

const swiperParams = {
    slidesPerView: 'auto',
}

const Stake = () => {
    const wallet = useWallet();
    const { program } = useContractInteractor();

    const [solBalance, setSolBalance] = useState<number>(0);
    const [jwlsolBalance, setJwlsolBalance] = useState<number>(0);
    const [jwlsolReserve, setJwlsolReserve] = useState<number>(0);

    const [jwlsolSupply, setJwlsolSupply] = useState<number>(0);
    const [sjwlsolReserve, setSJwlsolReserve] = useState<number>(0);
    const [sjwlsolBalance, setSjwlsolBalance] = useState<number>(0);
    const [sjwlsolSupply, setSjwlsolSupply] = useState<number>(0);
    const [userInfoAccountInfo, setUserInfoAccountInfo] = useState<IUserInfoAccount>();
    const [globalStateInfo, setGlobalStateInfo] = useState<IGlobalState>();
    const [epochInfo, setEpochInfo] = useState<IEpochInfo>();
    const [isNativeStakeExpanded, setIsNativeStakeExpanded] = useState<boolean>(false);
    const [isTokenStakeExpanded, setIsTokenSTakedExpanded] = useState<boolean>(false);
    const [solDepositAmount, setSolDepositAmount] = useState<number | null>();
    const [reserveRedeemAmount, setReserveRedeemAmount] = useState<number | null>();
    const [jwlsolStakeAmount, setJwlsolStakeAmount] = useState<number | null>();
    const [sjwlsolUnstakeAmount, setSjwlsolUnstakeAmount] = useState<number | null>();
    const [apy, setApy] = useState<number>(0);
    const [isDepositingSol, setIsDepositingSol] = useState<boolean>(false);
    const [isReservingRedeem, setIsReservingRedeem] = useState<boolean>(false);
    const [isStakingJwlsol, setIsStakingJwlsol] = useState<boolean>(false);
    const [isUnstakingJwlsol, setIsUnstakingJwlsol] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [jwlXrdBalance, setJwlXrdBalance] = useState<number>(0);
    const [sjwlXrdBalance, setSjwlXrdBalance] = useState<number>(0);
    const [xrdBalance, setXrdBalance] = useState<number>(0);
    const [nfts, setNfts] = useState<any[]>([]);

    // const initialize = async (isInit?: boolean) => {
    //     if (isInit) {

    //     }

    //     try {
    //         const [globalStateInfo, epochInfo] = await Promise.all([
    //             program.account.globalState.fetch(globalStatePubkey),
    //             program.provider.connection.getEpochInfo(),
    //         ]);

    //         let solBalance = 0;
    //         let jwlsolBalance = 0;
    //         let jwlsolSupply = 0;
    //         let sjwlsolBalance = 0;
    //         let sjwlsolSupply = 0;
    //         let apy = 0;
    //         let userInfoAccountInfo;

    //         if (wallet?.publicKey) {
    //             try {
    //                 const userInfoAccount = PublicKey.findProgramAddressSync(
    //                     [
    //                         Buffer.from('user-info-pda'),
    //                         wallet.publicKey.toBuffer(),
    //                     ],
    //                     program.programId
    //                 )[0];

    //                 userInfoAccountInfo = await program.account.userInfoAccount.fetch(userInfoAccount);
    //             } catch { }

    //             [solBalance, jwlsolBalance, jwlsolSupply, sjwlsolBalance, sjwlsolSupply, apy] = await Promise.all([
    //                 getBalance(wallet.publicKey),
    //                 getTokenBalance(wallet.publicKey, globalStateInfo.jwlsolMint),
    //                 getTokenSupply(globalStateInfo.jwlsolMint),
    //                 getTokenBalance(wallet.publicKey, globalStateInfo.sjwlsolMint),
    //                 getTokenSupply(globalStateInfo?.sjwlsolMint),
    //                 getApy(
    //                     Number(globalStateInfo.jwlsolReserve),
    //                     Number(globalStateInfo.sjwlsolReserve),
    //                     CONTRACT_DEPLOYED_AT,
    //                 ),
    //             ]);

    //             setUserInfoAccountInfo(userInfoAccountInfo);
    //         } else {
    //             [jwlsolSupply, sjwlsolSupply, apy] = await Promise.all([
    //                 getTokenSupply(globalStateInfo.jwlsolMint),
    //                 getTokenSupply(globalStateInfo?.sjwlsolMint),
    //                 getApy(
    //                     Number(globalStateInfo.jwlsolReserve),
    //                     Number(globalStateInfo.sjwlsolReserve),
    //                     CONTRACT_DEPLOYED_AT,
    //                 ),
    //             ]);
    //         }

    //         console.log({ globalStateInfo });
    //         console.log(globalStateInfo.jwlsolReserve);
    //         console.log(globalStateInfo.sjwlsolReserve);
    //         console.log(Number(globalStateInfo.jwlsolReserve));
    //         console.log(Number(globalStateInfo.sjwlsolReserve));
    //         setEpochInfo(epochInfo);
    //         setGlobalStateInfo(globalStateInfo);
    //         // setSolBalance(solBalance);
    //         // setJwlsolBalance(jwlsolBalance);
    //         // setJwlsolSupply(jwlsolSupply);
    //         // setSjwlsolBalance(sjwlsolBalance);
    //         // setSjwlsolSupply(sjwlsolSupply);
    //         //setApy(apy);
    //     } catch (e) {
    //         console.log('e', e);
    //     }

    // }

    const handleSetMaxDeposit = () => {
        const maxAmountToDeposit = xrdBalance - MAX_FEE_TO_DEPOSIT;
        console.log(maxAmountToDeposit);
        if (maxAmountToDeposit > 0) {
            setSolDepositAmount(Number((maxAmountToDeposit)));
        } else {
            setSolDepositAmount(0);
        }
    }

    const handleUnstakeMax = (sjwlXrdBalance:number) => {
        setSjwlsolUnstakeAmount(sjwlXrdBalance-MAX_FEE_TO_DEPOSIT);
    }



    const handleDepositSol = async () => {

        if (isLoading) {
            return toast.warn('Please connect wallet.');
        }

        if (!solDepositAmount) {
            return toast.warn('Please input amount to stake.');
        }

        if (solDepositAmount < MIN_DEPOSIT_AMOUNT) {
            return toast.warn(`Deposit amount should be higher than ${MIN_DEPOSIT_AMOUNT}.`);
        }



        setIsProcessing(true);
        setIsDepositingSol(true);

        try {
            await mintJwlXrd({
                accountAddress: accounts[0].address,
                xrdAmount:Number(solDepositAmount),
                xrdResourceAddress: xrdAddress

            })
                .map((response) => {
                    refresh()
                    console.log("status");
                    if(response.status=== "CommittedSuccess"){
                        toast.success('Successful to deposit XRD');
                    }
                    console.log(response.status);
                })
        } catch (e) {
            console.log('e', e);
            toast.error('Failed to deposit XRD');
        }

        setIsProcessing(false);
        setIsDepositingSol(false);
    }

    const handleReserveRedeem = async () => {


        if (!reserveRedeemAmount) {
            return toast.warn('Please input amount to reserve redeem');
        }

        if (reserveRedeemAmount > jwlXrdBalance) {
            return toast.warn('Insufficient JWLXRD balance');
        }


        setIsProcessing(true);
        setIsReservingRedeem(true);

        try {
            await redeemJwlXrd({
                accountAddress: accounts[0].address,
                jwlXrdAmount:Number(reserveRedeemAmount),
                jwlXrdResourceAddress: config.addresses.jwlxrdResourceAddress

            })
                .map((response) => {
                    refresh()
                    console.log("status");
                    if(response.status=== "CommittedSuccess"){
                        toast.success('Successful to redeem JWLXRD');
                    }
                    console.log(response.status);
                })
        } catch (e) {
            console.log('e', e);
            toast.error('Failed to reserve redeem.');
        }

        setIsProcessing(false);
        setIsReservingRedeem(false);
    }
    const { dispenseGcTokens, buyGumball, buyCandy, buyMemberCard, mintJwlXrd, stakeJwlXrd, unstakeSjwlXrd, redeemJwlXrd, withdrawXrdFromJwlXrdClaimNft } =
        useSendTransactionManifest()()
    const {
        refresh,
        state: { accounts, status, hasLoaded: hasAccountsLoaded },
    } = useAccounts()
    const wellKnownAddresses = useWellKnownAddresses()
    const xrdAddress = wellKnownAddresses?.xrd ? wellKnownAddresses?.xrd : "";
    const hasXrd = accounts.some(
        (account) =>
            account.fungibleTokens[xrdAddress] &&
            new BigNumber(account.fungibleTokens[xrdAddress].value).gt(0)
    )

    const [style, api] = useSpring(() => ({
        from: { opacity: 0 },
    }))
    const connectButtonState = useConnectButtonState()
    const { hasLoaded: hasPersonaLoaded } = usePersona()
    const isConnectButtonPending = connectButtonState === 'pending'
    const isLoading =
        !hasAccountsLoaded || !hasPersonaLoaded || !wellKnownAddresses
    const dAppToolkit = useDappToolkit()
    const gatewayApi = useGateway()
    const { fetchRadix } = useFetch()
    useEffect(() => {
        // console.log(`isloading: ${isLoading}`);
        // console.log(`isloading: ${hasAccountsLoaded}`);
        // console.log(wellKnownAddresses);
        // console.log(`isloading: ${hasXrd}`);
        // console.log(`isloading: ${isAccountsLoading}`);
        // console.log(`isloading: ${xrdAddress}`);
        // console.log(`isConnectButtonPending: ${isConnectButtonPending}`);


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                addresses: [
                  "resource_rdx1t5dpa60xk9a3092z8ed5wcr82y9m9xfv4eyjcdfyg7d3j4r7rhfx5p",
                  "resource_rdx1tklsaw4evqgmue59v7c87qm79tx2ppjp93ycxek8shrlmz426h7axq"
                ],
                aggregation_level: "Global"
              })
        };

      
            // .then(data => setPostId(data.id));
    
            fetchRadix(
            gatewayApi.state.getEntityDetailsVaultAggregated(config.addresses.jewelDefiComponent)
                .then(async (response) => {
                    const data = await (await fetch('https://mainnet.radixdlt.com/state/entity/details/', requestOptions)).json();
                
                        console.log("hi" + data.items[0].details.total_supply);
                        setSJwlsolReserve(Number(data.items[0].details.total_supply));
                        setJwlsolReserve(Number(data.items[1].details.total_supply));
            
                    console.log("entity fetched" + response);
                    console.log(response);
                    console.log(response.fungible_resources);

                    let jwlXrdSupply =0;
                    let xrdSupply = 0;
                    for (const fungible of response.fungible_resources.items) {
                        if(fungible.resource_address===config.addresses.jwlxrdResourceAddress){
                            for (const item of fungible.vaults.items){

                                jwlXrdSupply += Number(item.amount);
                            }
                        }
                        else{
                            for (const item of fungible.vaults.items){
                                xrdSupply += Number(item.amount);
                            }
                        }
                    }
                    console.log(jwlXrdSupply);
                    console.log(xrdSupply);
                    setSjwlsolSupply(jwlXrdSupply);
                    setJwlsolSupply(xrdSupply);

                    const apyTest = await getApy(jwlXrdSupply, Number(data.items[0].details.total_supply),  CONTRACT_DEPLOYED_AT );
                    console.log("apy" + apyTest);
                    setApy(apyTest);

                })
        ).then(

        )

        

        
       
    
            setXrdBalance(Number(accounts[0]?.fungibleTokens[xrdAddress]
                ?.value));
            setJwlXrdBalance(Number(accounts[0]?.fungibleTokens[config.addresses.jwlxrdResourceAddress]
                ?.value));
        setSjwlXrdBalance(Number(accounts[0]?.fungibleTokens[config.addresses.jwlxrdStakeUnitResourceAddress]
            ?.value));

        // console.log(accounts[0]?.nonFungibleTokens);
        const non = accounts[0]?.nonFungibleTokens;
        console.log("non_fungible_tokens");
        console.log(accounts);
        console.log(non);
        const tokens = accounts[0]?.nonFungibleTokens[config.addresses.xrdClaimNftResourceAddress];
        console.log(`tokens: ${tokens}`);

        if(tokens) {
            setNfts(tokens);

        }
        if (!isLoading) api.start({ opacity: 1 })
    }, [isLoading, api, accounts])




    const handleWiuthdrawJwlsol = async (nft:NonFungibleResource) => {

        setIsProcessing(true);
        // setIsStakingJwlsol(true);

        try {
            await withdrawXrdFromJwlXrdClaimNft({
                accountAddress: accounts[0].address,
                claimNft: nft

            })
                .map((response) => {
                    refresh()
                    console.log("status");
                    if (response.status === "CommittedSuccess") {
                        toast.success('Successful to redeem XRD');
                    }
                    console.log(response.status);
                })

        } catch (e) {
            console.log('e', e);
            toast.error('Failed to stake JWLXRD');
        }

        setIsProcessing(false);
    }


    const isAccountsLoading = status === 'pending'
    const handleStakeJwlsol = async () => {
        console.log(jwlsolStakeAmount);
        if (isLoading) {
            return toast.warn('Please connect wallet');
        }

        if (!jwlsolStakeAmount) {
            return toast.warn('Please input amount to stake');
        }

        if (jwlsolStakeAmount > jwlXrdBalance) {
            return toast.warn('Insufficient JWLXRD balance');
        }
        setIsProcessing(true);
        setIsStakingJwlsol(true);

        try {
            await stakeJwlXrd({
                accountAddress: accounts[0].address,
                jwlXrdAmount:Number(jwlsolStakeAmount),
                jwlXrdResourceAddress: config.addresses.jwlxrdResourceAddress

            })
                .map((response) => {
                    refresh()
                    console.log("status");
                    if(response.status=== "CommittedSuccess"){
                        toast.success('Successful to stake JWLXRD');
                    }
                    console.log(response.status);
                })

        } catch (e) {
            console.log('e', e);
            toast.error('Failed to stake JWLXRD');
        }

        setIsProcessing(false);
        setIsStakingJwlsol(false);
    }




    const handleUnstakSjwlsol = async () => {

        if (!sjwlsolUnstakeAmount) {
            return toast.warn('Please input amount to unstake');
        }

        if (sjwlsolUnstakeAmount > sjwlXrdBalance) {
            return toast.warn('Insufficient SJWLXRD balance');
        }

        setIsProcessing(true);
        setIsUnstakingJwlsol(true);

        try {
            await unstakeSjwlXrd({
                accountAddress: accounts[0].address,
                sJwlXrdAmount:Number(sjwlsolUnstakeAmount),

            })
                .map((response:any) => {
                    refresh()
                    console.log("status");
                    if(response.status=== "CommittedSuccess"){
                        toast.success('Successful to unstake sJWLXRD');
                    }
                    console.log(response.status);
                })

        } catch (e) {
            console.log('e', e);
            toast.error('Failed to unstake SJWLXRD');
        }

        setIsProcessing(false);
        setIsUnstakingJwlsol(false);
    }

    useEffect(() => {
        const getEpochInfo = async () => {
            const epochInfo = await program.provider.connection.getEpochInfo();
            setEpochInfo(epochInfo);
        }

        console.log("accounts");
        console.log(accounts);

        const interval = setInterval(() => {
            getEpochInfo();
        }, MINUTE_IN_MS);

        return () => clearInterval(interval);
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         // await initialize(true);
    //     })();
    // }, [wallet?.publicKey]);

    return (
        <div className='flex justify-center w-full mt-[56px] md:mt-[40px] px-[10.5px]'>
            <div className='flex flex-col w-full max-w-[1320px] bg-[#0f1720] rounded-[12px] p-[30px_20px] md:p-[30px]'>
                <div className='flex justify-between items-center w-full'>
                    <h1 className='text-[31.5px] md:text-[35px] text-white font-normal'>Stake</h1>

                    <div className='flex justify-center items-center w-[62px] md:w-[72px] h-[62px] md:h-[72px] border border-solid border-[#808080] rounded-[15px] p-[10px]'>
                        <div className='flex justify-center items-center w-[40px] md:w-[50px] h-[40px] md:h-[50px]'>
                            <img src={solLogo} alt='sol-logo' className='w-full' />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-[7px] w-full'>
                    <div className='text-[14px]'>JWLXRD is a XRD-pegged stablecoin.</div>
                    <div className='text-[14px]'>Mint XRD for JWLXRD 1:1 which will be sent for validator staking when able to. A dynamic % will be sent for LP creation. Stake JWLXRD for SJWLXRD which will accrue staking yield. You can now swap XRD-JWLXRD at <a href="https://ociswap.com/pools/component_rdx1czuprfuvc3rd9n9ms9t7ge2m53mp2mux9cparytzz77ct8xjrf0h3v/" target="_blank" rel="noreferrer">Ociswap.</a></div>
                </div>

                {/*<div className='flex flex-col w-full gap-[5px] mt-[14px]'>*/}
                {/*    <div className='text-[17px]'>List of Validators</div>*/}

                {/*    <div className='w-full'>*/}
                {/*        <Swiper {...swiperParams}>*/}
                {/*            {*/}
                {/*                wlValidators.map((item, index) => {*/}
                {/*                    return (*/}
                {/*                        <div*/}
                {/*                            key={index}*/}
                {/*                            className='flex justify-center items-center gap-[5px] !w-fit p-[7px]'*/}
                {/*                        >*/}
                {/*                            <img src={item.logo} alt='validator-logo' className='w-[30px] h-[30px]' />*/}
                {/*                            <div>{item.name}</div>*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </Swiper>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className='flex flex-col items-center mt-[21px]'>*/}
                {/*    <div className='text-[21px] text-[#54f5b7] leading-[1.2]'>{`Epoch ${epochInfo ? epochInfo.epoch : '-'}`}</div>*/}
                {/*    <div className='mt-[21px]'>{`${epochInfo ? convertSecondsToDateTime((epochInfo.slotsInEpoch - epochInfo.slotIndex) * 0.4) : 0} left until next Epoch`}</div>*/}
                {/*    <div className='w-full h-[14px] mt-[14px]'>*/}
                {/*        <LinearProgress*/}
                {/*            value={epochInfo ? (epochInfo.slotIndex / epochInfo.slotsInEpoch) * 100 : 0}*/}
                {/*            variant='determinate'*/}
                {/*            sx={{*/}
                {/*                height: '14px',*/}
                {/*                width: '100%',*/}
                {/*                borderRadius: '5px',*/}
                {/*                '&.MuiLinearProgress-root': {*/}
                {/*                    backgroundColor: 'white',*/}

                {/*                    '.MuiLinearProgress-bar': {*/}
                {/*                        backgroundColor: '#198754',*/}
                {/*                        backgroundImage: 'linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent)',*/}
                {/*                        backgroundSize: '14px 14px'*/}
                {/*                    }*/}
                {/*                }*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className='flex flex-col gap-[21px] w-full mt-[21px]'>
                    {/* Native staking */}
                    <div className='w-full bg-[rgb(18,18,18)] bg-[linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05))] rounded-[4px]'>
                        <Accordion
                            expanded={isNativeStakeExpanded}
                        >
                            <AccordionSummary
                                id='panel1a-header'
                                aria-controls='panel1a-content'
                                className='w-full !cursor-default'
                            >
                                <div className='grid grid-cols-12 w-full text-[12.6px] px-[10.5px]'>
                                    <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                        <div className='flex items-center'>
                                            <div className='flex justify-center items-center w-[50px] h-[50px] mx-[7px]'>
                                                <img src={solLogo} alt='sol-logo' className='w-full' />
                                            </div>
                                            <div>XRD</div>
                                        </div>
                                    </div>

                                    <div className='col-span-12 md:col-span-2 flex flex-col justify-center md:px-[10.5px]'>
                                        <div>TVL</div>
                                        <div>{`${(jwlsolReserve).toLocaleString()} JWLXRD`}</div>
                                    </div>

                                    <div className='col-span-12 md:col-span-1 md:px-[10.5px]'></div>

                                    <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                        <div className='flex justify-between items-center w-full'>
                                            <div>Your balance</div>
                                            <div className='text-end'>
                                                <div>{`${(xrdBalance).toLocaleString()} XRD`}</div>
                                                <div>{`${(jwlXrdBalance).toLocaleString()} JWLXRD`}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-span-12 md:col-span-1 md:px-[10.5px]'></div>

                                    <div className='col-span-12 md:col-span-2 flex items-center md:px-[10.5px]'>
                                        <button
                                            className='flex justify-center items-center w-full p-[7px] border border-solid border-[rgba(84,245,183,0.6)] rounded-[5px]'
                                            onClick={() => setIsNativeStakeExpanded(!isNativeStakeExpanded)}
                                        >
                                            <span>Mint / Redeem</span>
                                            {
                                                isNativeStakeExpanded ? (
                                                    <KeyboardArrowUpIcon className='text-white' />
                                                ) : (
                                                    <KeyboardArrowDownIcon className='text-white' />
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails
                                sx={{ padding: '0px 16px 16px' }}
                            >
                                <div className='grid grid-cols-12 gap-[20px] md:gap-0 w-full mt-[14px]'>
                                    <div className='col-span-12 md:col-span-6'>
                                        <div className='grid grid-cols-12 gap-[10px] md:gap-0 w-full'>
                                            <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                                <div>{`Avail XRD Balance: ${(xrdBalance).toLocaleString()} XRD`}</div>

                                                <InputBase
                                                    sx={{
                                                        flex: 1,
                                                        border: '1px',
                                                        borderStyle: 'solid',
                                                        borderRadius: '5px',
                                                        borderColor: 'gray',
                                                        padding: '2px 5px',
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={handleSetMaxDeposit}
                                                        >
                                                            Max
                                                        </InputAdornment>
                                                    }
                                                    type='number'
                                                    placeholder='0.00'
                                                    disabled={isProcessing || isDepositingSol}
                                                    value={solDepositAmount != null ? solDepositAmount : ''}
                                                    className='mt-[3.5px]'
                                                    onChange={e => setSolDepositAmount(e.target.value ? Number(e.target.value) : null)}
                                                />

                                                <button
                                                    disabled={isProcessing || isDepositingSol}
                                                    className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                                    onClick={handleDepositSol}
                                                >
                                                    {
                                                        !isDepositingSol ? (
                                                            <span>Mint</span>
                                                        ) : (
                                                            <div className='flex justify-center items-center gap-[10px]'>
                                                                <span className='text-white'>Processing...</span>
                                                                <TailSpin
                                                                    height="18"
                                                                    width="18"
                                                                    color="#ffffff"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </button>
                                            </div>

                                            <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                                <div>{`Avail JWLXRD Balance: ${(jwlXrdBalance).toLocaleString()} JWLXRD`}</div>

                                                <InputBase
                                                    sx={{
                                                        flex: 1,
                                                        border: '1px',
                                                        borderStyle: 'solid',
                                                        borderRadius: '5px',
                                                        borderColor: 'gray',
                                                        padding: '2px 5px',
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => setReserveRedeemAmount(jwlXrdBalance)}
                                                        >
                                                            Max
                                                        </InputAdornment>
                                                    }
                                                    type='number'
                                                    placeholder='0.00'
                                                    value={reserveRedeemAmount != null ? reserveRedeemAmount : ''}
                                                    className='mt-[3.5px]'
                                                    onChange={e => setReserveRedeemAmount(e.target.value ? Number(e.target.value) : null)}
                                                />

                                                <button
                                                    disabled={isProcessing || isReservingRedeem}
                                                    className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                                    onClick={handleReserveRedeem}
                                                >
                                                    {
                                                        !isReservingRedeem ? (
                                                            <span>Redeem</span>
                                                        ) : (
                                                            <div className='flex justify-center items-center gap-[10px]'>
                                                                <span className='text-white'>Processing...</span>
                                                                <TailSpin
                                                                    height="18"
                                                                    width="18"
                                                                    color="#ffffff"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='block md:hidden col-span-12 w-full italic px-[10.5px]'>
                                        If you have already requested JWLXRD to be redeemed, the new redeem attempts will increase the unbonding epoch - you will need to wait for the unbonding period again.
                                    </div>

                                    <div className='col-span-12 md:col-span-6'>
                                        <div className="row">
                                        </div>
                                        <div className='flex justify-center md:justify-center justify-content: center py-4'>
                                            <div>Claimable NFTs (need 10 passed days to claim XRD)</div>
                                        </div>
                                        <div>

                                            {
                                                isLoading || nfts.length === 0 ? (
                                                    <div className='flex justify-center md:justify-center justify-content: center'>
                                                        <div>{`-`}</div>
                                                    </div>
                                                ) : (
                                                    nfts.map((item:XrdClaimNonFungibleResource, index: number) => {
                                                        return <div>
                                                            <div className="flex flex-col md:flex-row w-full justify-content: space-between " style={{columnGap: "1rem"}}>
                                                                <div className='flex'>
                                                                    <div>Claimable XRD amount: {item.xrd_claim_amount}</div>
                                                                </div>
                                                                <div className='flex'>
                                                                    <div>Time passed: {convertSecondsToPrintDateTimeString(((Date.now()-Number(item.issued_on_instant)*1000))/1000) }</div>
                                                                </div>
                                                                {Math.abs(Date.now() - Number(item.issued_on_instant)*1000) / 1000 / 60 / 60 / 24 > 10 ? (
                                                                    <div className="eg-btn btn--primary2 capsule" style={{ cursor: 'pointer' }} onClick={()=>handleWiuthdrawJwlsol(item)}>
                                                                        {`Withdraw`}
                                                                    </div>
                                                                ): ""}

                                                            </div>
                                                        </div>
                                                    })
                                                )
                                            }
                                        </div>
                                        {/*<div className="flex flex-col md:flex-row w-full justify-content: space-between">*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div>XRD Reserved to Redeem</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div>XRD Reserved to Redeem</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div>XRD Reserved to Redeem</div>*/}
                                        {/*    </div>*/}

                                        {/*</div>*/}
                                        {/*<div className="flex flex-col md:flex-row w-full">*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div>XRD Reserved to Redeem</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div>XRD Reserved to Redeem</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className='flex'>*/}
                                        {/*        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }}>*/}
                                        {/*            {`Withdraw`}*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*</div>*/}
                                        {/*<div className='grid grid-cols-12 gap-[10px] md:gap-0 w-full'>*/}

                                        {/*    {*/}
                                        {/*        isLoading || nfts.length === 0 ? (*/}
                                        {/*            <div className='d-flex justify-content-center mt-4'>*/}
                                        {/*                <div>{`-`}</div>*/}
                                        {/*            </div>*/}
                                        {/*        ) : (*/}
                                        {/*            nfts.map((item:NonFungibleResource, index: number) => {*/}
                                        {/*                return <div>*/}
                                        {/*                        <div className='col-span-12 md:col-span-4 px-[10.5px]'>*/}
                                        {/*                        <div className='flex justify-start md:justify-center'>*/}
                                        {/*                            <div>XRD Reserved to Redeem</div>*/}
                                        {/*                        </div>*/}
                                        {/*                        <div className='flex justify-start md:justify-center mt-[5px] md:mt-[21px]'>*/}
                                        {/*                            <div>{userInfoAccountInfo && (userInfoAccountInfo?.reservedRedeemAmount.toNumber() / LAMPORTS_PER_SOL).toLocaleString()}</div>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}

                                        {/*                    <div className='col-span-12 md:col-span-4 px-[10.5px]'>*/}
                                        {/*                        <div className='flex justify-start md:justify-center'>*/}
                                        {/*                            <div>Unbonding Epoch</div>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className='col-span-12 md:col-span-4 px-[10.5px]'>*/}
                                        {/*                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={()=>handleWiuthdrawJwlsol(item)}>*/}
                                        {/*                                                            {`Withdraw`}*/}
                                        {/*                                                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            })*/}
                                        {/*        )*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* Token staking */}
                    <div className='w-full bg-[rgb(18,18,18)] bg-[linear-gradient(rgba(255,255,255,0.05),rgba(255,255,255,0.05))] rounded-[4px]'>
                        <Accordion
                            expanded={isTokenStakeExpanded}
                        >
                            <AccordionSummary
                                id='panel1a-header'
                                aria-controls='panel1a-content'
                                className='w-full !cursor-default'
                            >
                                <div className='grid grid-cols-12 w-full text-[12.6px] px-[10.5px]'>
                                    <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                        <div className='flex items-center'>
                                            <div className='flex justify-center items-center w-[50px] h-[50px] mx-[7px]'>
                                                <img src={jwlsolLogo} alt='jwlsol-logo' className='w-full' />
                                            </div>
                                            <div className='flex flex-col justify-center'>
                                                <div>JWLXRD</div>
                                                <div className='text-[11.2px]'>{`1 SJWLXRD = ${sjwlsolSupply > 0  && sjwlsolReserve > 0 ? (sjwlsolSupply / sjwlsolReserve).toFixed(5) : 1} JWLXRD`}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-span-12 md:col-span-2 flex flex-col justify-center md:px-[10.5px]'>
                                        <div>TVL</div>
                                        <div>{`${(sjwlsolReserve).toLocaleString()} SJWLXRD`}</div>
                                    </div>

                                    <div className='col-span-12 md:col-span-1 flex flex-col justify-center md:px-[10.5px]'>
                                        <div>APY</div>
                                        <div className='text-[#54f5b7]'>{`${apy.toPrecision(4)} %`}</div>
                                    </div>

                                    <div className='col-span-12 md:col-span-3 flex items-center md:px-[10.5px]'>
                                        <div className='flex justify-between items-center w-full'>
                                            <div>Your balance</div>
                                            <div className='text-end'>
                                                <div>{`${(jwlXrdBalance).toLocaleString()} JWLXRD`}</div>
                                                <div>{`${(sjwlXrdBalance).toLocaleString()} SJWLXRD`}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-span-12 md:col-span-1 md:px-[10.5px]'></div>

                                    <div className='col-span-12 md:col-span-2 flex items-center md:px-[10.5px]'>
                                        <button
                                            className='flex justify-center items-center w-full p-[7px] border border-solid border-[rgba(84,245,183,0.6)] rounded-[5px]'
                                            onClick={() => setIsTokenSTakedExpanded(!isTokenStakeExpanded)}
                                        >
                                            <span>Stake / Unstake</span>
                                            {
                                                isTokenStakeExpanded ? (
                                                    <KeyboardArrowUpIcon className='text-white' />
                                                ) : (
                                                    <KeyboardArrowDownIcon className='text-white' />
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails
                                sx={{ padding: '0px 16px 16px' }}
                            >
                                <div className='grid grid-cols-12 w-full mt-[14px]'>
                                    <div className='col-span-12 md:col-span-6'>
                                        <div className='grid grid-cols-12 gap-[10px] md:gap-0 w-full'>
                                            <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                                <div>{`Avail JWLXRD Balance: ${(jwlXrdBalance).toLocaleString()} JWLXRD`}</div>

                                                <InputBase
                                                    sx={{
                                                        flex: 1,
                                                        border: '1px',
                                                        borderStyle: 'solid',
                                                        borderRadius: '5px',
                                                        borderColor: 'gray',
                                                        padding: '2px 5px',
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => setJwlsolStakeAmount(jwlXrdBalance)}
                                                        >
                                                            Max
                                                        </InputAdornment>
                                                    }
                                                    type='number'
                                                    placeholder='0.00'
                                                    disabled={isProcessing || isStakingJwlsol}
                                                    value={jwlsolStakeAmount != null ? jwlsolStakeAmount : ''}
                                                    className='mt-[3.5px]'
                                                    onChange={e => setJwlsolStakeAmount(e.target.value ? Number(e.target.value) : null)}
                                                />

                                                <button
                                                    disabled={isProcessing || isStakingJwlsol}
                                                    className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                                    onClick={handleStakeJwlsol}
                                                >
                                                    {
                                                        !isStakingJwlsol ? (
                                                            <span>Stake</span>
                                                        ) : (
                                                            <div className='flex justify-center items-center gap-[10px]'>
                                                                <span className='text-white'>Processing...</span>
                                                                <TailSpin
                                                                    height="18"
                                                                    width="18"
                                                                    color="#ffffff"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </button>
                                            </div>

                                            <div className='col-span-12 md:col-span-6 flex flex-col px-[10.5px]'>
                                                <div>{`Avail SJWLXRD Balance: ${(sjwlXrdBalance).toLocaleString()} SJWLXRD`}</div>

                                                <InputBase
                                                    sx={{
                                                        flex: 1,
                                                        border: '1px',
                                                        borderStyle: 'solid',
                                                        borderRadius: '5px',
                                                        borderColor: 'gray',
                                                        padding: '2px 5px',
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => handleUnstakeMax(sjwlXrdBalance)}
                                                        >
                                                            Max
                                                        </InputAdornment>
                                                    }
                                                    type='number'
                                                    placeholder='0.00'
                                                    disabled={isProcessing || isUnstakingJwlsol}
                                                    value={sjwlsolUnstakeAmount != null ? sjwlsolUnstakeAmount : ''}
                                                    className='mt-[3.5px]'
                                                    onChange={e => setSjwlsolUnstakeAmount(e.target.value ? Number(e.target.value) : null)}
                                                />

                                                <button
                                                    disabled={isProcessing || isUnstakingJwlsol}
                                                    className='flex justify-center items-center w-fit p-[7px_21px] mt-[7px] btn-primary2'
                                                    onClick={handleUnstakSjwlsol}
                                                >
                                                    {
                                                        !isUnstakingJwlsol ? (
                                                            <span>Unstake</span>
                                                        ) : (
                                                            <div className='flex justify-center items-center gap-[10px]'>
                                                                <span className='text-white'>Processing...</span>
                                                                <TailSpin
                                                                    height="18"
                                                                    width="18"
                                                                    color="#ffffff"
                                                                    ariaLabel="tail-spin-loading"
                                                                    radius="1"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-12 md:col-span-6'>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stake;
