import {
  FungibleResourcesVaultCollection,
  NonFungibleResourcesVaultCollection,
  State,
} from '@radixdlt/babylon-gateway-api-sdk'
import { BigNumber } from 'bignumber.js'
import {config} from "../config";
import {StateNonFungibleDetailsResponseItem} from "@radixdlt/babylon-gateway-api-sdk/dist/generated";

export type FungibleResource = {
  type: 'fungible'
  value: string
  address: string
}

export const transformFungibleTokens = async (
  fungibles?: FungibleResourcesVaultCollection
): Promise<Record<string, FungibleResource>> => {
  if (!fungibles || fungibles.items.length === 0) {
    return {}
  }

  return fungibles.items.reduce<Record<string, FungibleResource>>(
    (acc, item) => {
      return {
        ...acc,
        [item.resource_address]: {
          type: 'fungible',
          address: item.resource_address,
          value:
            item.vaults?.items
              .reduce((prev, next) => prev.plus(next.amount), new BigNumber(0))
              .toString() || '0',
        },
      }
    },
    {}
  )
}


export type NonFungibleResource = {
  type: 'non-fungible'
  id: string
  address: string
}

export type XrdClaimNonFungibleResource = {
  type: 'non-fungible'
  id: string
  address: string
  xrd_claim_amount:number
  issued_on_instant:string


}


export const transformNonFungibleTokens = async (
    stateApi: State,
  nonFungibles?: NonFungibleResourcesVaultCollection,
) => {
  console.log("starting transformaion");
  console.log(nonFungibles);
  if (!nonFungibles || nonFungibles.items.length === 0) {
    return []
  }

  const ids = [];
  for (const nonFungible of nonFungibles.items) {
    for (const singleNftData of nonFungible.vaults.items) {
      console.log("singleNftData");
      console.log(singleNftData);

      for (const id of singleNftData.items || []) {
        ids.push(id);
      }
    }
  }

  const transformedNonFungiblesXrd: XrdClaimNonFungibleResource[] = []

  try{
  const result:any = await stateApi.getNonFungibleData(config.addresses.xrdClaimNftResourceAddress, ids);
  console.log("found");
  console.log(result);
  for (const value of result){
    console.log(value.non_fungible_id);
    let xrd_claim_amount = 0;
    let issued_on_instant = "";
    for (const innerValue of value.data?.programmatic_json?.fields){
      console.log("found" + innerValue);
      if(innerValue.field_name==="xrd_claim_amount"){
        xrd_claim_amount = Number(innerValue.value);
      }
      else if(innerValue.field_name==="issued_on_instant"){
        console.log("found" + String(Number((Date.now() - innerValue.value*1000)/86400/1000)));
        issued_on_instant = String(innerValue.value);
      }

    }
    transformedNonFungiblesXrd.push({
      type: 'non-fungible',
      id: value.non_fungible_id,
      issued_on_instant: issued_on_instant,
      xrd_claim_amount: xrd_claim_amount,
      address: config.addresses.xrdClaimNftResourceAddress
    })
  }
}
catch (e){
  console.log(e);
}


  return transformedNonFungiblesXrd.reduce<Record<string, XrdClaimNonFungibleResource[]>>(
    (acc, curr) => {
      const resourceManager = acc[curr.address] || []
      resourceManager.push(curr)
      return { ...acc, [curr.address]: resourceManager }
    },
    {}
  )
}

export const formatLocalDateTime = (milliseconds: number): string => {
  return (new Date(milliseconds)).toLocaleString();
};

export const SECOND_TO_MILLISECONDS = 1000;
